export const urls = [
  {
    name: 'about',
    path: '/info/about/',
  },
  {
    app: true,
    name: 'about',
    path: '/info/about/',
  },
  {
    name: 'advisory-board',
    path: '/info/advisory-board/',
  },
  {
    app: true,
    name: 'advisory-board',
    path: '/info/advisory-board/',
  },
  {
    name: 'buy-with-confidence',
    path: '/info/buy-with-confidence/',
  },
  {
    app: true,
    name: 'buy-with-confidence',
    path: '/info/buy-with-confidence/',
  },
  {
    name: 'careers',
    path: '/info/careers/',
  },
  {
    app: true,
    name: 'careers',
    path: '/info/careers/',
  },
  {
    name: 'contact',
    path: '/info/contact/',
  },
  {
    app: true,
    name: 'contact',
    path: '/info/contact/',
  },
  {
    name: 'explore-our-tools',
    path: '/info/explore-our-tools/',
  },
  {
    app: true,
    name: 'explore-our-tools',
    path: '/info/explore-our-tools/',
  },
  {
    name: 'privacy-policy',
    path: '/info/privacy-policy/',
  },
  {
    app: true,
    name: 'privacy-policy',
    path: '/info/privacy-policy/',
  },
  {
    name: 'return-policy',
    path: '/info/return-policy/',
  },
  {
    app: true,
    name: 'return-policy',
    path: '/info/return-policy/',
  },
  {
    name: 'seller-guide',
    path: '/info/seller-guide/',
  },
  {
    app: true,
    name: 'seller-guide',
    path: '/info/seller-guide/',
  },
  {
    name: 'team',
    path: '/info/team/',
  },
  {
    app: true,
    name: 'team',
    path: '/info/team/',
  },
  {
    name: 'terms-of-use',
    path: '/info/terms-of-use/',
  },
  {
    app: true,
    name: 'terms-of-use',
    path: '/info/terms-of-use/',
  },
  {
    name: 'tos-artist',
    path: '/info/tos-artist/',
  },
  {
    app: true,
    name: 'tos-artist',
    path: '/info/tos-artist/',
  },
  {
    name: 'tos-designers',
    path: '/info/tos-designers/',
  },
  {
    app: true,
    name: 'tos-designers',
    path: '/info/tos-designers/',
  },
  {
    name: 'tos-gallery',
    path: '/info/tos-gallery/',
  },
  {
    app: true,
    name: 'tos-gallery',
    path: '/info/tos-gallery/',
  },
  {
    name: 'aiSearch',
    path: '/ai-search/',
  },
  {
    name: 'aiSearchDetail',
    path: '/ai-search/:uuid/',
  },
  {
    app: true,
    name: 'artzineDetail',
    path: '/artzine/:year/:month/:day/:blogPostSlug/',
  },
  {
    name: 'artzineSellerList',
    path: '/sell/learn/',
  },
  {
    name: 'artzineSellerSearch',
    path: '/sell/learn/search/',
  },
  {
    name: 'artzineSellerDetail',
    path: '/sell/learn/:blogPostSlug/',
  },
  {
    name: 'artzineAdvisoryList',
    path: '/advisory/blog/',
  },
  {
    name: 'artzineAdvisorySearch',
    path: '/advisory/blog/search/',
  },
  {
    name: 'artzineAdvisoryDetail',
    path: '/advisory/blog/:blogPostSlug/',
  },
  {
    name: 'artzineList',
    path: '/artzine/',
    appPath: 'artzine/home/',
  },
  {
    name: 'artzineAuthor',
    path: '/artzine/authors/:author/',
  },
  {
    name: 'artzinePreview',
    path: '/artzine/preview/:blogPostId/',
  },
  {
    name: 'artzineSearch',
    path: '/artzine/search/',
  },
  {
    name: 'artzineCategory',
    path: '/artzine/categories/:categorySlug/',
    appPath: 'artzine/:categorySlug/',
  },
  {
    name: 'artzineCityArtGuide',
    path: '/artzine/city-art-guides/',
  },
  {
    name: 'artzineCityArtGuideDetail',
    path: '/artzine/city-art-guides/:citySlug/',
  },
  {
    name: 'artzineDetail',
    path: '/artzine/:blogPostSlug/',
  },
  {
    name: 'home',
    path: '/',
    appPath: 'shop/home/',
  },
  {
    name: 'components',
    path: '/components/',
  },
  {
    name: 'contactSuccess',
    path: '/info/contact/success/',
  },
  {
    name: 'collections',
    path: '/collections/',
  },
  {
    name: 'collectionsCategory',
    path: '/collections/:categorySlug/',
  },
  {
    name: 'collectionDetailView',
    path: '/collections/:categorySlug/:collectionSlug/',
  },
  {
    name: 'collectionDetailPreviewStaffView',
    path: '/staff/collections/:categorySlug/:collectionSlug/preview/',
  },
  {
    name: 'changeEmail',
    path: '/user/account/email/update/',
  },
  {
    name: 'verifyCurrentEmail',
    path: '/user/account/email/verify-current-email/',
  },
  {
    name: 'verifyNewEmail',
    path: '/user/account/email/verify-new-email/',
  },
  {
    name: 'login',
    path: '/login/',
    appPath: 'profile/home/',
  },
  {
    name: 'loginFacebook',
    path: '/login/facebook/',
  },
  {
    name: 'signup',
    path: '/signup/',
    appPath: 'profile/home/',
  },
  {
    name: 'verifySignup',
    path: '/signup/verify/',
  },
  {
    name: 'tradeSignup',
    path: '/trade-signup/',
  },
  {
    name: 'tradeSignupSuccess',
    path: '/trade-signup/success/',
  },
  {
    name: 'tradeView',
    path: '/trade/',
  },
  {
    name: 'consultancy',
    path: '/consultancy/',
  },
  {
    name: 'consultancyTeam',
    path: '/consultancy/team/',
  },
  {
    name: 'consultancyTestimonials',
    path: '/consultancy/testimonials/',
  },
  {
    name: 'consultancyProjects',
    path: '/consultancy/projects/',
  },
  {
    name: 'consultancyProjectsTag',
    path: '/consultancy/projects/tags/:tag/',
  },
  {
    name: 'consultancyProjectsAll',
    path: '/consultancy/projects/all/',
  },
  {
    name: 'consultancyProjectsService',
    path: '/consultancy/projects/:serviceSlug/',
  },
  {
    name: 'consultancyProjectsProjectDetail',
    path: '/consultancy/project/:serviceSlug/:projectSlug/',
  },
  {
    name: 'artFairArtistDetail',
    path: '/events/:artFairId/artists/:artistId/:artistSlug/',
  },
  {
    name: 'artFairArtworkDetail',
    path: '/events/:artFairId/artwork/:artworkId/:artworkSlug/',
  },
  {
    name: 'artFairGalleryDetail',
    path: '/events/:artFairId/galleries/:galleryId/:gallerySlug/',
  },
  {
    name: 'artFairList',
    path: '/events/:artFairId/:artFairSlug/',
  },
  {
    name: 'artistDetail',
    path: '/:categorySlug(artist|designer|print-artist)/:slug/',
    appPath: 'shop/artist/:slug/',
  },
  {
    name: 'artistList',
    path: '/:categorySlug(artists|designers|print-artists)/:arg1?/:arg2?/',
  },
  {
    name: 'calendarDetail',
    path: '/calendar/:eventSlug/',
  },
  {
    name: 'calendarPastEvents',
    path: '/calendar/past-events/',
  },
  {
    name: 'calendarUpcomingEvents',
    path: '/calendar/upcoming-events/',
  },
  {
    name: 'delistCheckBase',
    path: '/availability/',
  },
  {
    name: 'delistCheckHome',
    path: '/availability/:uuid/',
  },
  {
    name: 'delistCheckBulk',
    path: '/availability/bulk/:uuid/',
  },
  {
    name: 'delistCheckProduct',
    path: '/availability/:uuid/:productId/',
  },
  {
    name: 'delistCheckExpired',
    path: '/availability/expired/',
  },
  {
    name: 'delistCheckCompleted',
    path: '/availability/completed/',
  },
  {
    name: 'sellerSalesShippingQuote',
    path: '/order-item/:orderItemUuid/',
  },
  {
    name: 'seller',
    path: '/seller/',
  },
  {
    name: 'sellerProfile',
    path: '/seller/profile/',
  },
  {
    name: 'sellerProducts',
    path: '/seller/products/',
  },
  {
    name: 'sellerBulkEdit',
    path: '/seller/bulk-edit/',
  },
  {
    name: 'sellerArtists',
    path: '/seller/artists/',
  },
  {
    name: 'sellerGalleryUpdateArtist',
    path: '/seller/artists/:artistId/',
  },
  {
    name: 'sellerAddArtist',
    path: '/seller/artists/add/',
  },
  {
    name: 'sellerAddArtistNew',
    path: '/seller/artists/add/new/',
  },
  {
    name: 'sellerSubmitAddress',
    path: '/seller/address/',
  },
  {
    name: 'sellerSales',
    path: '/seller/sales/',
  },
  {
    name: 'sellerDetailRequest',
    path: '/seller/sales/details/:orderItemConditionUuid/',
  },
  {
    name: 'sellerConditionCheck',
    path: '/seller/sales/:orderItemConditionUuid/',
  },
  {
    name: 'sellerSettings',
    path: '/seller/settings/',
  },
  {
    name: 'sellerSocialShare',
    path: '/seller/social-share/',
  },
  {
    name: 'sellerAddProduct',
    path: '/seller/products/new/:category/',
  },
  {
    name: 'sellerUpdateProduct',
    path: '/seller/products/:category/:productId/',
  },
  {
    name: 'sellerPreviewProduct',
    path: '/seller/product/preview/:productSlug/',
  },
  {
    name: 'cart',
    path: '/cart/',
    appPath: 'cart/home/',
  },
  {
    name: 'cartEmpty',
    path: '/cart/empty/',
    appPath: 'cart/home/',
  },
  {
    name: 'cartPaymentChoice',
    path: '/cart/checkout/payment/',
  },
  {
    name: 'cartShippingAddress',
    path: '/cart/checkout/address/',
    appPath: 'cart/address/',
  },
  {
    name: 'checkoutDiscount',
    path: '/checkout/discount/',
  },
  {
    name: 'checkoutInformation',
    path: '/checkout/information/',
  },
  {
    name: 'checkoutShipping',
    path: '/checkout/shipping/',
  },
  {
    name: 'checkoutPayment',
    path: '/checkout/payment/',
  },
  {
    name: 'concierge',
    path: '/concierge/',
    appPath: 'concierge/home/',
  },
  {
    name: 'conciergeSuccess',
    path: '/concierge/success/',
    appPath: 'concierge/success/',
  },
  {
    name: 'faq',
    path: '/faq/',
  },
  {
    name: 'favoritesCatalogue',
    path: '/user/my-favorites/:labelSlug/catalogue/',
  },
  {
    name: 'favoritesShare',
    path: '/favorites/share/:userId/:allOrList(all|list)/:label?/',
  },
  {
    name: 'favoritesShareVanity',
    path: '/favorites/share/:vanityUrl/',
  },
  {
    name: 'galleryDetail',
    path: '/galleries/:gallerySlug/',
  },
  {
    name: 'galleryList',
    path: '/galleries/',
  },
  {
    name: 'giftcards',
    path: '/giftcards/',
  },
  {
    name: 'inquirySellerResponseForm',
    path: '/inquiries/:uuid/',
  },
  {
    name: 'moods',
    path: '/moods/',
  },
  {
    name: 'newsletterError',
    path: '/newsletter/error/',
  },
  {
    name: 'newsletterSignup',
    path: '/newsletter/signup/',
  },
  {
    name: 'newsletterSuccess',
    path: '/newsletter/success/',
  },
  {
    name: 'partnerDetail',
    path: '/partners/:curatorCategorySlug/:curatorSlug/',
  },
  {
    name: 'partnerList',
    path: '/partners/',
  },
  {
    name: 'deleteAccountConfirmed',
    path: '/delete-user/',
  },
  {
    name: 'passwordChange',
    path: '/password/change/',
  },
  {
    name: 'passwordReset',
    path: '/password/reset/',
  },
  {
    name: 'passwordResetDone',
    path: '/password/reset/done/',
  },
  {
    name: 'passwordResetSuccess',
    path: '/password/reset/success/',
  },
  {
    name: 'passwordResetKey',
    path: '/password/reset/key/:key/',
  },
  {
    name: 'passwordResetKeySuccess',
    path: '/password/reset/key/success/',
  },
  {
    name: 'pressDetail',
    path: '/press/:articleId/',
  },
  {
    name: 'pressList',
    path: '/press/',
  },
  {
    name: 'productArtShopGrid',
    path: '/art/:arg1?/:arg2?/:arg3?/:arg4?/:arg5?/',
    appPath: 'shop/art/:filters?/',
  },
  {
    name: 'productNFTShopGrid',
    path: '/nfts/:arg1?/:arg2?/:arg3?/:arg4?/:arg5?/',
  },
  {
    name: 'productBooksShopGrid',
    path: '/books/:arg1?/:arg2?/',
    appPath: 'shop/books/:filters?/',
  },
  {
    name: 'productDesignShopGrid',
    path: '/designs/:arg1?/:arg2?/:arg3?/:arg4?/:arg5?/',
    appPath: 'shop/design/:filters?/',
  },
  {
    name: 'productPrintsShopGrid',
    path: '/prints/:arg1?/:arg2?/:arg3?/:arg4?/:arg5?/',
    appPath: 'shop/prints/:filters?/',
  },
  {
    name: 'productDetail',
    path: '/:categorySlug(artwork|design|print|book|nft)/:fullSlug/',
    appPath: 'shop/product/:fullSlug',
  },
  {
    name: 'staffProductPreview',
    path: '/staff/products/:slug/preview/',
  },
  {
    name: 'staffProducts',
    path: '/staff/products/',
  },
  {
    name: 'staffInquiries',
    path: '/staff/inquiries/',
  },
  {
    name: 'profileAccountInfo',
    path: '/user/account/',
    appPath: 'profile/account/',
  },
  {
    name: 'profileTrade',
    path: '/user/trade/',
  },
  {
    name: 'profileAddArtist',
    path: '/user/artists/add/',
  },
  {
    name: 'profileAddNewArtist',
    path: '/user/artists/add/new/',
  },
  {
    name: 'profileBillingAndShipping',
    path: '/user/billing-and-shipping/',
    appPath: 'profile/shipping-billing/',
  },
  {
    name: 'profileMyArtistDetail',
    path: '/user/artists/:artistId/',
  },
  {
    name: 'profileMyArtists',
    path: '/user/artists/',
  },
  {
    name: 'profileMyFavorites',
    path: '/user/my-favourites/',
    appPath: 'profile/my-favorites',
  },
  {
    name: 'profileMyOrderDetail',
    path: '/user/orders/:orderPk/',
    appPath: 'profile/orders/:orderPk/',
  },
  {
    name: 'profileMyOrderDetailNew',
    path: '/user/orders/:orderUUID/details/',
  },
  {
    name: 'profileMyOrderSuccess',
    path: '/user/orders/:orderUUID/success/',
  },
  {
    name: 'profileMyOrderCancel',
    path: '/user/orders/:orderUUID/cancel/',
  },
  {
    name: 'profileMyOrders',
    path: '/user/orders/',
    appPath: 'profile/orders/',
  },
  {
    name: 'profileSelfUpdate',
    path: '/user/profile/',
  },
  {
    name: 'profileUpdateAgreement',
    path: '/agreement/',
  },
  {
    name: 'profileUpdateContactInfo',
    path: '/user/update-contact-info/',
  },
  {
    name: 'purchaseCancel',
    path: '/purchase/cancel/',
  },
  {
    name: 'search',
    path: '/search/',
  },
  {
    name: 'semanticSearch',
    path: '/ssearch/',
  },
  {
    name: 'staff',
    path: '/staff/',
  },
  {
    name: 'staffTradeSubmissions',
    path: '/staff/trade-submissions/',
  },
  {
    name: 'staffAutoCreate',
    path: '/staff/auto-create/',
  },
  {
    name: 'staffImpersonate',
    path: '/staff/impersonate/',
  },
  {
    name: 'staffShippingCost',
    path: '/staff/shipping-cost/',
  },
  {
    name: 'staffOrders',
    path: '/staff/orders/',
  },
  {
    name: 'staffOfflineCarts',
    path: '/staff/offline-carts/',
  },
  {
    name: 'staffInquiries',
    path: '/staff/inquiries/',
  },
  {
    name: 'staffConditionCheck',
    path: '/staff/orders/:orderItemConditionUuid/',
  },
  {
    name: 'staffSubmissions',
    path: '/staff/submissions/',
  },
  {
    name: 'staffSubmissionsAccept',
    path: '/staff/submissions/:submissionId/accept/',
  },
  {
    name: 'staffSubmissionsDismiss',
    path: '/staff/submissions/:submissionId/dismiss/',
  },
  {
    name: 'staffSubmissionsReject',
    path: '/staff/submissions/:submissionId/reject/',
  },
  {
    name: 'staffArtlingProjectsInquiries',
    path: '/staff/artling-projects-inquiries/',
  },
  {
    name: 'submissions',
    path: '/submissions/',
  },
  {
    name: 'submissionsArtwork',
    path: '/submissions/:submitterCategory/:uuid/artwork/:artworkId?/',
  },
  {
    name: 'submissionsConfirmation',
    path: '/submissions/:submitterCategory/:uuid/confirmation/',
  },
  {
    name: 'submissionsInformation',
    path: '/submissions/:submitterCategory/:uuid/information/',
  },
  {
    name: 'submissionsLogin',
    path: '/submissions/:submitterCategory/login/',
  },
  {
    name: 'submissionsSuccess',
    path: '/submissions/success/',
  },
  {
    name: 'testCenter',
    path: '/test-center/',
  },
  {
    name: 'testGraphqlError',
    path: '/test-graphql-error/',
  },
  {
    name: 'modalsFooterPages',
    path: '/modals/footer-page/:pageSlug/',
  },
  {
    name: 'modalsHide',
    path: '/modals/hide/',
  },
  {
    name: 'setCurrency',
    path: '/currency/',
  },
  {
    name: 'stripeCreateIntent',
    path: '/stripe/create-intent/',
  },
  {
    name: 'projectsDetailView',
    path: '/user/projects/:uuid/',
  },
  {
    name: 'projectsDetailRendersView',
    path: '/user/projects/:uuid/renders/',
  },
  {
    name: 'projectsCatalogueView',
    path: '/user/projects/:uuid/catalogue/',
  },
  {
    name: 'projectsListView',
    path: '/user/projects/',
  },
  {
    name: 'projectsArchivedListView',
    path: '/user/projects/archived/',
  },
] as const
